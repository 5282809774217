.terms-page {
  main {
    .container {
      position: relative;

      section {
        padding-left: 260px;
        display: none;
        &.active {
          display: block;
        }

        h4 {
          font-size: 18px;
          margin: 35px 0 5px;
          font-weight: 500;
        }

        p {
          margin: 0;
          text-align: justify;
        }

        strong {
          color: var(--dark-gray);
        }

        ul {
          margin: 0;
          padding: 0;
        }
        li {
          list-style: none;
        }
      }
    }

    .sidebar {
      position: absolute;
      top: 60px;
      left: 0;
      display: flex;
      flex-direction: column;

      &--item {
        color: var(--gray);
        padding: 19px 0 16px 18px;
        font-size: 15px;
        line-height: 16px;
        font-weight: 600;
        border-left: 3px solid transparent;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;

        &.active {
          color: var(--violet);
          border-left-color: var(--violet);
        }
      }
    }
  }
}
