form {
  font-family: 'Poppins', sans-serif;
  &.minimalist-form {
    &.single-line {
      display: inline-flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 79%;
    }
    .input-field,
    .input-group {
      background-color: transparent;
      padding: 15px 10px 11px;
      border: solid 2px transparent;
      border-bottom-color: var(--light-gray);
      width: calc(100% - 190px);
      &::placeholder {
        color: var(--dark-gray);
      }
      &:focus,
      &:hover {
        border-bottom-color: var(--blue);
      }
    }
    .input-group {
      transition: all 0.4s;
      position: relative;
      label {
        position: absolute;
        top: 21px;
        left: 10px;
        z-index: -1;
        transition: all 0.4s;
        font-size: 12px;
        line-height: 20px;
        color: var(--dark-gray);
      }
      .input-field {
        border: none;
        padding: 0;
        width: 100%;
        font-size: 16px;
        &:focus {
          & ~ label {
            top: -9px;
            left: 10px;
            font-size: 11px;
            color: var(--gray);
          }
        }
      }

      &.has-value {
        border-bottom-color: var(--blue);
        label {
          top: -9px;
          left: 10px;
          font-size: 11px;
          color: var(--gray);
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .input-field {
        -moz-appearance: textfield;
      }
    }
  }
  .input-field {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    transition: all 0.4s;
    &:focus {
      outline: none;
      border-bottom-color: var(--blue);
    }
  }

  .server-error {
    color: var(--light-red);
    font-size: 13px;
    padding: 0;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.4s;

    &.with-server-error {
      padding: 5px 0 30px;
      transform: scale(1);
      opacity: 1;
    }
  }

  .form-row {
    margin-bottom: 20px;
    transition: all 0.4s;
    padding-bottom: 0;

    .form-error {
      position: absolute;
      transition: all 0.4s;
      opacity: 0;
      bottom: -30px;
      left: 0;
      transform: scale(0.5);
      color: var(--light-red);
      font-size: 13px;
    }

    .form-field-with-icon {
      .form-error {
        bottom: -60px;
      }
    }

    &.with-error {
      position: relative;
      padding-bottom: 30px; 

      .form-error {
        opacity: 1;
        bottom: 0;
        transform: scale(1);
      }

      .form-field-with-icon {
        .form-error {
          bottom: -30px;
        }
      }
    }

    &.duplet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 20px;

      .form-error {
        bottom: -60px;
      }

      &.with-error {
        .form-error {
          bottom: -30px;
        }
      }
    }
    .input-field,
    textarea {
      font-size: 13px;
      padding: 12px 13px;
      border: 2px solid rgba(25, 29, 58, 0.05);
      border-radius: 5px;
      width: 100%;
      &:focus {
        border-color: #938fe0;
        outline: none;
      }
      &::placeholder {
        color: var(--dark-gray);
        opacity: 0.7;
      }
    }
    textarea {
      font-family: 'Poppins', sans-serif;
      height: 150px;
      resize: none;
    }
    .form-field-with-icon {
      position: relative;
      svg {
        position: absolute;
        left: 16px;
        top: 14px;
      }
      .input-field {
        padding-left: 45px;
      }
    }
  }
}

.form-heading-block {
  h4 ~ .form-steps {
    margin-top: 35px;
    margin-bottom: 30px;
  }
  .form-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
}

.controls-group {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.dark-gray-panel {
  & &__button {
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: var(--gray);
    .filter-by {
      color: var(--light-red);
      span {
        transition: all 0.4s;
      }
      .asc {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}
